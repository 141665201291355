<template>
  <v-hover v-slot:default="{ hover }">
    <v-card>
  
      <v-container :id="speaker.name.split(' ').join('')">
        <v-row style="text-align: center" align="center" justify="center">
          <v-col sm="12" md="6" my-1>
            <v-avatar class="ma-3" size="200">
              <v-img :src="speaker.image" />
            </v-avatar>
          </v-col>
          <v-col sm="12" md="6" my-1>
            <v-card-title
              class="text-h5"
              style="color:rgb(4 68 51) !important;font-weight:bold"
            >
              {{ speaker.name }}
            </v-card-title>
            <v-card-subtitle
              v-text="speaker.title"
              style="color:rgb(4 68 51) !important;font-weight:bold"
            ></v-card-subtitle>
          </v-col>
        </v-row>
      </v-container>
      <div class="d-flex flex-no-wrap justify-space-between">
        <v-expansion-panels class="">
          <v-expansion-panel>
            <v-expansion-panel-header
              disable-icon-rotate
              style="color:rgb(4 68 51) !important;"
            >
              Bio
              <template v-slot:actions>
                <v-icon color="error">
                  mdi-plus
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text v-html="speaker.bio" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  props: ["speaker", "width"],
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    openBio() {
      this.dialog = true;
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
// V-card text breaks halfway through words on this version of vuetify so this is necessary
.v-card__text,
.v-card__title {
  word-break: normal;
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
#img {
  cursor: pointer;
}
</style>
