<template>
  <div>
    <v-app-bar fixed style="min-height:110px;">
      <v-img
        :src="require('../assets/wids_phil_2024.jpg')"
        contain
        max-width="420"
        style="margin-top:42px"
      />
      <v-spacer />
      <v-row>
        <v-col>
          <ul class="nav" style="margin-top:34px">
            <li>
              <a href="#about" style="font-size:20px">About WiDS</a>
            </li>
            <li>
              <a href="#speaker" style="font-size:20px">Speakers</a>
            </li>
            <li>
              <a href="#program" style="font-size:20px">Program</a>
            </li>
            <li>
              <a href="#register" style="font-size:20px">Register</a>
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-app-bar fixed style="margin-top:110px;height:84px" class="mobile-menu">
      <v-row>
        <v-col>
          <ul class="nav" style="margin-top:20px">
            <li>
              <a href="#about" style="font-size:20px">About WiDS</a>
            </li>
            <li>
              <a href="#speaker" style="font-size:20px">Speakers</a>
            </li>
            <li>
              <a href="#program" style="font-size:20px">Program</a>
            </li>
            <li>
              <a href="#register" style="font-size:20px">Register</a>
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-app-bar>
    <section id="register">
      <a name="home"></a>
      <v-container
        style="background-color:#4ab969;width: 100%;padding-top: 135px;padding-bottom: 50px;"
        :fluid="true"
      >
        <v-row>
          <v-col :cols="cardCols" style="margin: auto">
            <h1 class="text-xs-center" style="font-size:50px;">
              2024 WiDS Philadelphia Conference
            </h1>

            <div
              class="text-xs-center subtitle white--text"
              style="{text-align: center, background-color: white , shadow-box: 1px solid}"
            >
              <!-- <h2>Stay tuned for more information about WiDS Philadelphia 2022!</h2> -->
              <h2 style="font-size:35px">
                Thriving in the AI Era
              </h2>
              <h2>Conference Date: December 10th, 2024 8:00am - 4:30pm</h2>
              <h2>
                Venue: {{ locationAddress.line1 }}
                <br />
                {{ locationAddress.line2 }}
                <br />
                {{ locationAddress.line3 }}
              </h2>
              <h2>Registration now open for WiDS Philadelphia 2024!</h2>
              <!-- <p class="subtitle">
            To keep in touch, connect with us on the Women in Data Science
            Philadelphia LinkedIn Group. If you are interested in being a
            speaker or sponsor for WiDS Philadelphia 2023, please email
            <strong style="color: red">
              <address>
                <a class="white_link" href="mailto:widsphiladelphia@vgimfs.com"
                  >widsphiladelphia@vgimfs.com</a
                >
              </address>
            </strong>
          </p> -->
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-container>
              <v-row>
                <v-col>
                  <v-btn
                    class="mb-2"
                    outlined
                    large
                    block
                    href="https://www.eventbrite.com/e/2024-wids-philadelphia-conference-thriving-in-the-ai-era-tickets-1068838788589?aff=oddtdtcreator"
                    color="white"
                    style=" background-color: #006A4E;"
                    ><span style="{font-size: 25px}"
                      >Register for WIDS 2024 In-Person</span
                    ></v-btn
                  >
                </v-col>
                <v-col>
                  <v-btn
                    class="mb-2"
                    outlined
                    large
                    block
                    href="https://www.eventbrite.com/e/2024-wids-philadelphia-conference-thriving-in-the-ai-era-virtual-tickets-1082758011359?utm_experiment=test_share_listing&aff=ebdsshios"
                    color="white"
                    style=" background-color: #006A4E;"
                    ><span style="{font-size: 25px}"
                      >Register for WIDS 2024 Virtual</span
                    ></v-btn
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn
                    class="mb-2"
                    outlined
                    large
                    block
                    href="https://www.eventbrite.com/e/generative-ai-technical-webinar-fine-tuning-gemini-in-ai-studio-and-vertex-tickets-1092477743339?aff=oddtdtcreator"
                    color="white"
                    style=" background-color: #006A4E;"
                    ><span style="{font-size: 25px}"
                      >Gen AI Webinar Registration</span
                    ></v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
      <v-container id="about" style="height:100px">
        <v-row>
          <v-col> </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <v-col>
            <div class="factoid-box">
              <h1 style="color:black">200+</h1>
              <h2 style="color:#4ab969;text-align:center;">events</h2>
            </div>
          </v-col>
          <v-col>
            <div class="factoid-box">
              <h1 style="color:black">10,000+</h1>
              <h2 style="color:#4ab969;text-align:center;">participants</h2>
            </div>
          </v-col>
          <v-col>
            <div class="factoid-box">
              <h1 style="color:black">500+</h1>
              <h2 style="color:#4ab969;text-align:center;">ambassadors</h2>
            </div>
          </v-col>
          <v-col>
            <div class="factoid-box">
              <h1 style="color:black">175+</h1>
              <h2 style="color:#4ab969;text-align:center;">countries</h2>
            </div>
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-row>
          <v-col>
            <h2 style="color:rgb(4 68 51) !important; font-style:italic">
              WiDS (Women in Data Science) is an independent event organized by
              WiDS Philadelphia @ Vanguard to coincide with the annual Global
              Women in Data Science (WiDS) Conference held at Stanford
              University and an estimated 150+ locations worldwide. All genders
              are invited to attend WiDS regional events, which features
              outstanding women doing outstanding work.
            </h2>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <v-col id="program">
            <v-btn
              class="mb-2"
              style="background-color:rgb(4 68 51) !important;color:white;width: 300px !important;min-width: 400px !important;margin: 0 auto;margin-top:20px;"
              outlined
              large
              block
              href="https://www.widsconference.org/"
            >
              <h2>
                About WiDS Worldwide
              </h2>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <v-col>
            <v-container>
              <v-row>
                <v-col>
                  <h1 style="color:rgb(4 68 51) !important;">Program</h1>
                </v-col>
              </v-row>
              <v-row
                style="border-bottom:1px solid #e8e8e8;padding:10px"
                v-for="item in eventSchedule"
                :key="item.name"
              >
                <v-col cols="3">
                  <h2 style="font-size:20px">{{ item.time }}</h2>
                </v-col>
                <v-col cols="9">
                  <h2 style="color:green;font-size:20px">{{ item.title }}</h2>
                  <h2 style="color:blue;font-size:20px">
                    {{ item.description }}
                  </h2>
                  <p
                    style="font-size: 18px;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;color:rgb(4 68 51) !important;"
                    v-for="(speaker, index) in item.speakers"
                    :key="index"
                  >
                    <a
                      @click="
                        $vuetify.goTo('#' + speaker.split(',')[0].split(' ').join(''), {
                          offset: 100
                        })
                      "
                    >
                      {{ speaker }}
                    </a>
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3"> </v-col>
                <v-col cols="9" style="border-bottom: 4px dashed blue;">
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3"> </v-col>
                <v-col cols="9">
                  <h2>
                    <span style="color:blue;">
                      Generative AI Technical Webinar:</span
                    >
                    <span style="font-weight:bold;">
                      Fine Tuning Gemini in AI Studio and Vertex AI with Roya
                      Kandalan, PhD, AI Research Scientist</span
                    >| December 12 @ 1:00 - 2:00p.m Learn advanced fine-tuning
                    techniques using Google AI Studio and Vertex AI to enhance
                    the performance and accuracy of large language model
                  </h2>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
      <v-container id="speaker" style="height:65px">
        <v-row>
          <v-col> </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <v-col>
            <h1
              class="text-xs-center black--text my-5"
              style="color:rgb(4 68 51) !important;"
            >
              Speakers
            </h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-container grid-list-xl>
              <v-row dense class="mb-1">
                <v-col
                  v-for="(speaker, index) of panelSpeakers"
                  v-bind:key="index"
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <SpeakerImage
                    :speaker="speaker"
                    :width="350"
                    style="margin:20px"
                  ></SpeakerImage>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <!-- <v-row v-if="mediumScreen">
            <v-col cols="6">
              <v-btn
                outlined
                large
                block
                href="https://www.youtube.com/playlist?list=PLcTh7H2gOc8brsUOrF6CDAGO_tVXbXaBW"
                color="white"
                >View WiDS Philadelphia 2021 Recordings</v-btn
              >
            </v-col>
            <v-col cols="6">
              <v-btn
                outlined
                large
                block
                href="https://www.linkedin.com/groups/13823400/"
                color="white"
                >Connect with us on Linkedin</v-btn
              >
            </v-col>
          </v-row> -->

        <!-- <v-row v-if="$vuetify.breakpoint.smAndDown">
            <v-btn
              class="mb-2"
              outlined
              large
              block
              href="https://www.youtube.com/playlist?list=PLcTh7H2gOc8brsUOrF6CDAGO_tVXbXaBW"
              color="white"
              >View WiDS Philadelphia 2021 Recordings</v-btn
            >
          </v-row>
          <v-row v-if="$vuetify.breakpoint.smAndDown">
            <v-btn
              class="mb-2"
              outlined
              large
              block
              href="https://www.linkedin.com/groups/13823400/"
              color="white"
              >Connect with us</v-btn
            >
          </v-row> -->

        <!-- <v-row style="text-align: center;">
            <v-col>
              <b class="subtitle-1 white--text">
                *All registration proceeds will go to
                <v-img
                  contain
                  src="../assets/CodedbyKids_logo.png"
                  height="30"
                />For more information, visit
                <a class="white_link" href="http://www.codedbykids.com/"
                  >their website.</a
                >
              </b>
            </v-col>
          </v-row> -->
      </v-container>
    </section>

    <!-- TODO Add Back Later when we have speaker images -->

    <section style="{padding: 25px;background-color:rgb(74, 185, 105);}">
      <a name="participants"></a>
      <v-container grid-list-xl class="mb-7">
        <h1 class="text-xs-center black--text my-5" style="font-size:50px;">
          This Conference Was Made Possible By:
        </h1>
        <v-row align="center" justify="center" class="mb-1">
          <v-col
            v-for="(src, index) in corporateSponsorImages"
            v-bind:key="index"
            cols="12"
            sm="6"
            md="4"
            my-1
          >
            <v-img :src="src" />
          </v-col>
        </v-row>
        <!-- <v-divider class="mb-3" /> -->
        <!-- <v-row align="center" justify="center" class="mb-1">
          <v-col
            v-for="(src, index) in uniSponsorImages"
            v-bind:key="index"
            cols="12"
            sm="6"
            md="4"
            my-1
          >
            <v-img :src="src" />
          </v-col>
        </v-row> -->
      </v-container>
    </section>

    <!-- <section style="background: #ececec" height="800" class="mb-10 pb-10">
      <a name="involved"></a>
      <v-container grid-list-xl>
        <h1 class="text-xs-center black--text my-5">Get Involved</h1>
        <v-row>
          <v-card color="blue" class="white--text">
            <v-card-title class="display-1" justify="center">
              Student Poster Submission
            </v-card-title>

            <v-card-text>
              <div class="white--text" style="font-size: 22px">
                <p>
                  Are you a student interested in showcasing your work? WiDS
                  Philadelphia will host a mid-day Student Poster Session as
                  part of its April 4th virtual conference. The Student Poster
                  Session will give students a chance to share their data
                  science-related research and projects, receive feedback, and
                  network with data scientists in the Philadelphia region. All
                  student presenters must identify as a woman to align with the
                  WiDS Worldwide guidelines.
                </p>
                <b
                  >Please submit your posters and abstracts to
                  <strong>
                    <address>
                      <a
                        class="black_link"
                        href="mailto:widsphiladelphia@vgimfs.com"
                        >widsphiladelphia@vgimfs.com</a
                      >
                    </address>
                  </strong></b
                >
              </div>
            </v-card-text>
          </v-card>
        </v-row>
        <v-row style="text-align: center" align="center" justify="center">
          <v-col cols="12" sm="6" md="4" my-1>
            <p class="font-weight-bold button_label">
              Join our LinkedIn Group:
            </p>
            <v-btn
              href="https://www.linkedin.com/groups/13823400/"
              color="secondary"
              elevation="11"
              x-large
              tile
              target="_blank"
              >Join</v-btn
            >
          </v-col>
          <v-col cols="12" sm="6" md="4" my-1>
            <p class="font-weight-bold button_label">
              Find a local WIDS Community:
            </p>
            <v-btn
              href="https://www.womenindata.org/join-us"
              color="secondary"
              elevation="11"
              x-large
              tile
              target="_blank"
              >Find</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </section> -->

    <!-- <v-parallax height="800" :src="require('../assets/quorum.png')">
      <a name="register"></a>
      <v-container>
        <h1 class="text-xs-center my-5">Register</h1>
        <v-layout column align-center justify-center my-3>
          <div
            class="headline text-xs-center white--text"
            style="text-align: center"
          >
            {{ locationAddress.line1 }}
            <br />
            {{ locationAddress.line2 }}
            <br />
            {{ locationAddress.line3 }}
          </div>
          <br />
          <iframe
            v-if="mobileScreen"
            class="mb-5"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1529.158181399081!2d-75.195219!3d39.956679!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c6c656cebe36a3%3A0x6b50ac518b4dbd49!2s3675%20Market%20St%2C%20Philadelphia%2C%20PA%2019104!5e0!3m2!1sen!2sus!4v1570714279488!5m2!1sen!2sus"
            width="375"
            height="450"
            frameborder="0"
            style="border: 0"
            allowfullscreen
          ></iframe>
          <iframe
            v-else
            class="mb-5"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d764.5790862274936!2d-75.1957662!3d39.9566794!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c6c656cebe36a3%3A0x6b50ac518b4dbd49!2s3675%20Market%20St%2C%20Philadelphia%2C%20PA%2019104!5e0!3m2!1sen!2sus!4v1569517520115!5m2!1sen!2sus"
            width="600"
            height="450"
            frameborder="0"
            style="border: 0"
            allowfullscreen
          ></iframe>
        </v-layout>
      </v-container>
    </v-parallax> -->

    <v-footer fixed>
      For more info —
      <strong>
        <address>
          <a class="black_link" href="mailto:VanguardWiDS@vanguard.com"
            >VanguardWiDS@vanguard.com</a
          >
        </address>
      </strong>
      <v-spacer />
      <div>
        <v-btn
          icon
          small
          class="mx-2"
          v-for="(item, index) of widsLinks"
          v-bind:key="index"
          :href="item.link"
        >
          <v-icon>{{ item.icon }}</v-icon>
        </v-btn>
      </div>
    </v-footer>
    <!--<v-footer color="transparent" fixed>
       TODO: remove this once coronavirus is gone :(
      <v-row align="center" justify="center">
        <v-alert
          class="title"
          type="warning"
          color="red"
          border="left"
          prominent
          dismissible
        >
          NEWS: WiDS Philadelphia 2020 postponed due to growing concerns over
          COVID-19 virus
        </v-alert>
      </v-row>
    </v-footer> --->
  </div>
</template>

<script>
// import Timeline from "./Timeline";
import { eventSchedule } from "../eventInfo.js";
import SpeakerImage from "./SpeakerImage";
import {
  widsLinks,
  corporateSponsorImages,
  uniSponsorImages,
  registerLink,
  eventDate,
  locationAddress,
} from "../eventInfo.js";
import { keynoteSpeakers, panelSpeakers } from "../speakerInfo.js";

export default {
  data() {
    return {
      widsLinks: widsLinks,
      registerLink: registerLink,
      corporateSponsorImages: corporateSponsorImages,
      uniSponsorImages: uniSponsorImages,
      eventDate: eventDate,
      locationAddress: locationAddress,
      keynoteSpeakers: keynoteSpeakers,
      eventSchedule: eventSchedule,
      panelSpeakers: panelSpeakers,
    };
  },
  computed: {
    cardCols() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return 12;
      }
      return 12;
    },
    parallaxImageHeight() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "700";
      }
      return "600";
    },
    mobileScreen() {
      return this.$vuetify.breakpoint.xs;
    },
    mediumScreen() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
  },
  components: {
    SpeakerImage,
    // Timeline
  },
};
</script>

<style scoped lang="scss">
// V-card text breaks halfway through words on this version of vuetify so this is necessary
.v-card__text,
.v-card__title {
  word-break: normal;
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}

.scrollable {
  max-height: 700px;
  overflow-y: auto;
}
.infotext {
  width: 75%;
  font-size: 24px;
}
h1 {
  text-align: center;
  font-size: 70px;
  font-weight: 800;
  font-family: Roboto, Impact, Charcoal, sans-serif;
  color: white;
}
h2 {
  font-size: 25px;
}
h3 {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  font-family: Roboto, Impact, Charcoal, sans-serif;
  color: white;
}

// Links
.black_link:link {
  color: black;
}
.black_link:visited {
  color: black;
}
.black_link:hover {
  color: blue;
}
.white_link:link {
  color: white;
}
.white_link:visited {
  color: white;
}
.white_link:hover {
  color: blue;
}

.button_label {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  font-family: Roboto, Impact, Charcoal, sans-serif;
}
.mobile-menu {
  display: none;
}

// Header
.container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  color: #444;
  font-size: 14px;
  font-weight: 300;
  font-family: Roboto, "Open Sans", Arial, sans-serif;
  overflow: hidden;
}
.hold {
  height: 40px;
}
.header {
  line-height: 40px;
  width: 100%;
  transition: line-height 0.2s linear, box-shadow 0.2s linear;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background: rgba(245, 245, 245, 0.97);
}
.header.small {
  line-height: 30px;
  box-shadow: 0px 1px 3px 0px rgba(50, 50, 50, 0.8);
}
ul.nav {
  float: right;
  list-style: none;
  margin: 0;
  padding: 0 !important;
}
ul.nav li {
  float: left;
  position: relative;
}
ul.nav li a {
  transition: color 0.2s linear;
  font-size: 18px;
}
ul.nav li:hover a {
  color: red;
}
ul.nav li a {
  padding: 21px !important;
  color: initial;
  text-decoration: initial;
}

.subtitle {
  width: 80%;
  text-align: center;
  // margin: auto;
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
}

@media all and (max-width: 1200px) {
  h1 {
    font-size: 40px;
    font-weight: 800;
    font-family: Roboto, Impact, Charcoal, sans-serif;
    color: white;
  }

  .header {
    height: auto;
    background: #eee;
  }
  .infotext {
    width: 100%;
    font-size: 16px;
  }
  ul.nav {
    float: none;
    display: block;
    text-align: center;
    margin: 0 auto;
  }
  ul.nav li {
    float: initial;
    display: inline-block;
  }
  ul.nav li a {
    transition: color 0.2s linear;
    font-size: 12px;
  }
}
.factoid-box {
  border: 1px solid #42424230;

  height: 200px;

  padding-top: 20px;
}
@media all and (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media all and (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media all and (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

@media all and (max-width: 456px) {
  .mobile-menu {
    display: block;
  }
}
</style>
