export const registerLink =
  "https://www.eventbrite.com/e/women-in-data-science-philadelphia-2021-tickets-137655327791";

export const eventDate = {
  // Human readable date
  date: "December 10th, 2024",
  // Machine readable date (used so that search engines can just grab the date from our site and use it)
  // YYYY-MM-DD
  machineReadableDate: "2024-12-10"
};

export const corporateSponsorImages = [
  require("./assets/sponsors/vg_logo.png"),
  require("./assets/sponsors/cdao.png")
];

export const uniSponsorImages = [
  // require("./assets/sponsors/stevens.png"),
  // require("./assets/sponsors/UPenn.png"),
  // require("./assets/sponsors/wharton.png")
];

export const eventSchedule = [
  {
    title: "Welcome Breakfast & Registration",
    description: "",
    speakers: [],
    time: "8:00 AM"
  },
  {
    title: "Opening Remarks",
    description: "",
    speakers: ["Jing Wang, Principal, Center for Analytics and Insights, Vanguard"],
    time: "9:00 AM"
  },
  {
    title: "Fireside Chat: AI Driving Business Outcomes",
    description: "Discuss how AI drives tangible business outcomes through innovation and collaboration.",
    speakers: [
      "Christina Selby, Principal, Head of Operational Enablement, Personal Investor, Vanguard",
      "Steve Holman, Principal, Head of Distribution Enablement, Institutional Investor Group, Vanguard",
      "Vanessa Richards, Global Head of Fraud Prevention and Physical Security, Global Risk & amp; Security, Vanguard",
      "Sharon Hill, Head of Alpha Equity Investments, Investment Management Group, Vanguard",
      "Panel Moderator: Hong Qiu, Head of Data Science and Machine Learning, Center of Analytics Insights, Vanguard"
    ],
    time: "9:15 AM"
  },
  {
    title: "Proxy Validation and Verification for Critical AI Systems",
    description: "Learn the latest research on constructing and validating proxy systems for critical AI systems.",
    speakers: ["Joanna F. DeFranco, Associate Professor of Software Engineering, Pennsylvania State University"],
    time: "10:15 AM"
  },
  {
    title: "Intermission",
    description: "",
    speakers: "",
    time: "10:45 AM"
  },
  {
    title: "Marketing Personalization and Acceleration in Marriott",
    description: "Explore how Marriott leverages data-driven marketing personalization to drive customer engagement and business growth.",
    speakers: ["Liang Long, Senior Director of Global Marketing and Media Science, Marriott International"],
    time: "11:00 AM"
  },
  {
    title: "Code Modernizer - Revolutionizing Legacy Code Modernization with Generative AI",
    description: "Delve into the potential of Generative AI to transform massive legacy code into modern, maintainable, and scalable code",
    speakers: ['Sharron Xiaolu Tang, Principal AI/ML Scientist, Center of Analytics Insights, Vanguard'],
    time: "11:30 AM"
  },
  {
    title: "Lunch & Networking",
    description: "",
    speakers: [],
    time: "12:00 PM"
  },
  {
    title: "Fireside Chat: How to Thrive in an AI Career",
    description: "Elevate your AIML career: Learn transition strategies and growth insights from industry leaders!",
    speakers: [
      "Jing Wang, Principal, Head of Center of Analytics and Insights, Vanguard",
      "Lorraine Knerr, Global Head of GenAI and Data Solutions Strategy and Architecture, AWS",
      "Shana Sood, VP, Head of Core Performance Marketing Channels and Advanced Analytics, Capital One",
      "Panel Moderator: Giuliana Quiroz, Head of Marketing Science, Center of Analytics Insights, Vanguard"
    ],
    time: "1:00 PM"
  },
  {
    title: "DocumentAI: Harnessing Computer Vision and NLP to build Intelligent Document Processes",
    description: "Explore the latest AI/ML technologies used to automate and optimize semi-structured document review processes",
    speakers: [
      "Caroline Harrison, Data Scientist, Center of Analytics and Insights, Vanguard",
    ],
    time: "2:00 PM"
  },
  {
    title: "Applications of artificial intelligence in accelerating pharmaceutical research and development.",
    description: "Discover real-world examples and advanced technologies shaping the future of Pharma R&D",
    speakers: [
      "Hannah Horng, Senior Scientist in Biostatistics, Merck",
    ],
    time: "2:30 PM"
  },
  {
    title: "Intermission",
    description: "",
    speakers: [],
    time: "3:00 PM"
  },
  {
    title: "Panel Discussion: Responsible AI",
    description: "Learn from top minds in academia and industry on AI ethics and practical strategies in AI development and deployment",
    speakers: [
      "Susan B.Davidson, Weiss Professor of Computer and Information Science, University of Pennsylvania",
      "Kimberly Ryan, Senior Director, Head of Data Defense Office, Vanguard",
      "Seung - Youn Oh, Associate Professor, Department of Political Science, Bryn Mawr College",
      "Tingni Sun, Senior Manager, Data Science, Center of Analytics and Insights, Vanguard",
      "Panel Moderator: Kennedy Ellison, Data Scientist, Center of Analytics and Insights, Vanguard"
    ],
    time: "3:10 PM"
  },
  {
    title: "Closing Remarks and Thank You",
    description: "",
    speakers: ['Hong Qiu, Head of Data Science and Machine Learning, Center of Analytics and Insights, Vanguard'],
    time: "4:10 PM"
  },
];

export const widsLinks = [
  // Icon here refers to the name of Material Design Icons
  {
    icon: "mdi-twitter",
    link: "https://twitter.com/WiDS_Worldwide"
  },
  {
    icon: "mdi-facebook",
    link: "https://www.facebook.com/WiDSWorldwide"
  },
  {
    icon: "mdi-linkedin",
    link:
      "https://www.linkedin.com/company/women-in-data-science-wids-at-stanford-university/"
  },
  {
    icon: "mdi-instagram",
    link: "https://www.instagram.com/wids_worldwide/"
  },
  {
    icon: "mdi-youtube",
    link: "https://www.youtube.com/channel/UCbyzlQEnV1_MYM4Cbp_7eWg"
  }
];

// NOTE: if you're changing this, you will also probably want to change the Google Maps directions at the bottom.
// For those, search the location up on Google Maps. Click the sidebar -> "Embed or share map" -> "Embed map"
// Do this twice for both screen sizes and replace the "iframe" components found at the bottom of Home.vue
export const locationAddress = {
  line1: "Desmond Hotel",
  line2: "One Liberty Blvd",
  line3: "Malvern, PA 19355"
};

// export const headerImage = require("./assets/web_header_green_wide.png")
