

export const panelSpeakers = [
  {
    name: "Jing Wang",
    image: require("./assets/headshots/jing_wang.png"),
    title: "Head of Center for Analytics and Insights, Vanguard",
    bio:
      `Jing leads Vanguard’s enterprise AI/ML and Applied Behavioral Science function. Her team enables Vanguard’s businesses to transform client experiences, investor outcomes, and business operations, through the application of complex and large-scale AI / Machine Learning and behavioral science solutions.<br/><br/>Before her current position, Jing held various leadership roles at Vanguard, including head of the Vanguard Retirement Plan Access™ business which serves thousands of small businesses and their employees to save for retirement. She also led sales channel management in Vanguard Financial Advisor Services™, and multiple enterprise strategy projects in Vanguards Corporate Strategy group. She joined Vanguard in 2009 after several years as a Principal at the Boston Consulting Group.<br/><br/> Jing earned a B.S. in physics from the University of Science and Technology of China, a Ph.D. from the University of Pennsylvania, and an M.B.A. in Finance and Marketing from the Wharton School of Business, University of Pennsylvania. Jing holds 7, 65 and 24 licenses.`
  },
  {
    name: "Christina Selby",
    image: require("./assets/headshots/christina_selby.png"),
    title: "Principal, Head of Operational Enablement, Personal Investor, Vanguard",
    bio:
      `Christina Selby is the head of Operational Enablement at Vanguard, where her team stewards the success of clients, crew, and business strategies for Vanguard Personal Investor. Before this role, Christina served as business unit chief financial officer for Vanguard Institutional Investor Group. Before joining Vanguard, Christina spent 20 years at GE Capital working in a variety of CFO and global audit roles across the United States, Australia, and Mexico. Christina earned a B.S. in finance and international business from The Pennsylvania State University. She serves on the advisory board of Summer Search Philadelphia and the Penn State Smeal Business School Finance Advisory Board.`
   },
  {
    name: "Steve Holman",
    image: require("./assets/headshots/steve_holman.png"),
    title: "Principal and Head of IIG Distribution Enablement, Institutional Investor Group, Vanguard",
    bio:
      `Since the beginning of 2023, Steve Holman has been the principal and head of IIG Distribution Enablement, a group focused on enhancing productivity, impact and consistency of our institutional client-facing crew. Before his current role, he was the head of the Client Success Program Office, a team tasked with managing the implementation of strategic changes to Vanguard’s retirement business. Steve and his team helped lead the charge in designing and executing all aspects of transformation within Vanguard Institutional Investor Group. He also served as head of Vanguard Retirement Plan Access™, a comprehensive suite of retirement plan services for defined contribution plans under $20 million. Since joining Vanguard in 2004, he has served in leadership roles in Institutional Sales Operations, the Portfolio Review Department, Flagship Services®, Insurance Services, IRA Services, and, in his first role at Vanguard, was a project manager in Corporate Strategy.<br/><br/> Steve was the co-lead for the Vanguard Black Professional Network for five years. He led the crew campaign for the United Way in 2012. He earned a B.A. in English from Georgetown University and an M.B.A. in finance from the Wharton School of the University of Pennsylvania. He holds FINRA Series 7, 63, and 24 licenses.`
     },
  {
    name: "Vanessa Richards",
    image: require("./assets/headshots/vanessa_richards.png"),
    title: "Global Head of Fraud Prevention and Physical Security, Global Risk & Security, Vanguard",
    bio:
      "Vanessa Richards has a wealth of knowledge and experience, having served as an accomplished attorney and leader in fraud risk management and investigations. She has extensive experience identifying, investigating, and mitigating complex cyber fraud across large-scale enterprises. <br/><br/>Prior to joining Vanguard, Vanessa created and then led PayPal's Fraud Defense Cyber Center (FDCC), the company's first fraud fusion center. Prior to joining PayPal, Vanessa served as a state and federal prosecutor for a dozen years. At the U.S. Attorney's Office for the District of Connecticut, Vanessa led national security and cyber-related criminal investigations of computer hacking and fraud, botnets, dark web distribution of narcotics, business email compromises, unlawful bitcoin exchanges, economic and political espionage, and other complex federal violations. In that office, she served first as the Computer Hacking and Intellectual Property Coordinator for the District and then as the Deputy of the National Security and Cyber Crimes Unit. In these roles, she built a cyber practice between the DOJ and federal law enforcement agencies responsible for investigating cybercrimes. Prior to joining the U.S. Attorney's Office, Vanessa served as an Assistant District Attorney at the New York District Attorneys Office, where she investigated and prosecuted organized crime, with many of her cases focusing on corruption in public projects.<br/><br/>Before her tenure with the government, Vanessa was a Litigation Associate at the New York City firms of Friedman Kaplan Seiler & Adelman, LLP and Paul, Weiss, Rifkind, Wharton & Garrison, LLP. Vanessa received her B.A. in Political Science from Barnard College, Columbia University, and her J.D. from the University of Pennsylvania."
    },
  {
    name: "Sharon Hill",
    image: require("./assets/headshots/sharron_hill.png"),
    title:
      "Head of Alpha Equity Investments - Global & Income, investment Management, Vanguard",
    bio:
      "Sharon Hill, Ph.D, is a senior portfolio manager and head of Alpha Equity—Global & Income within Vanguard’s Quantitative Equity Group. Her team manages active equity global and income-oriented mandates using quantitative methods. Before joining Vanguard in 2019, she was Head of Equity Quantitative Research & Analytics at Macquarie Investment Management. Before spending 19 years at Macquarie, she worked as a fixed income software developer at Bloomberg L.P., and taught mathematics at Rowan University. She earned a B.S. in mathematics from City University of New York at Brooklyn College and an M.S. and Ph.D. in mathematics from University of Connecticut."
    },
  {
    name: "Hong Qiu",
    image: require("./assets/headshots/hong_qiu.png"),
    title: "Head of Data Science and Machine Learning, Corporate Services, Vanguard",
    bio:
      "Hong is an accomplished AI/ML leader with 16+ years of experience in Data Science and Consulting. Over the past 5 years at Vanguard, she drives transformative AI/ML initiatives, delivering impactful business outcomes across Corporate Services divisions, Enterprise Marketing and Direct-to-Consumer business. Prior to her current position, Hong led Vanguard Personal Investor Data Science team and Marketing Data Science team. Before Vanguard, Hong was a Senior Director of Advanced Analytics at Publicis Groupe Epsilon and an Associate Director of Quantitative Solution Group at dentsu Merkle, guiding organizations across diverse industries towards AI-driven success. Hong earned a B.S. in Economics from Anhui Normal University and a Master of Applied Statistics from The Ohio State University."
    },
    {
      name: "Joanna F. DeFranco",
      image: require("./assets/headshots/Joanna_defransco.png"),
      title: "Associate Professor of Software Engineering, Associate Director, Doctor of Engineering in Engineering Program, The Pennsylvania State University",
      bio:
        "Joanna F. DeFranco, earned her Ph.D. in computer and information science from New Jersey Institute of Technology, M.S. in computer engineering from Villanova University, and a B.S. in Electrical Engineering and Math from Penn State University. She is an Associate Professor of Software Engineering with the Pennsylvania State University. She has worked as an Electronics Engineer for the Navy as well as a Software Engineer at Motorola. Dr. DeFranco is a guest researcher at the National Institute of Standards and Technology (NIST) working with the Secure Systems and Applications group. She is a senior member of the IEEE and on the advisory board for IEEE Computer Magazine. Her research interests include software engineering Software Security, Distributed Networks, and Internet of Things."
      },
    {
      name: "Liang Long",
      image: require("./assets/headshots/liang_long.png"),
      title: "Senior Director of Global Marketing and Media Science at Marriott International",
      bio:
        "Liang Long is a Data Science leader spearheading data science teams in Marriott to shape data, product, marketing and overall business strategy through advanced data science and data-driven solutions.  Liang is currently leading Marriott Global Marketing and Media Data Science team and using statistical techniques and methodologies to optimize marketing strategies across paid, earned, and owned media, including experimental techniques for measuring marketing incrementality and Marketing Mix Modeling rollout.  Prior to the current role, Liang led the Co-brand Credit Card Data Science team and activated Marriott’s first Machine Learning Model which drove record-high card acquisitions.  Liang also led Global Digital Product Analytics team for 3 years in Marriott and during her tenure, she tripled the team size and built Marriott Digital Product Analytics capabilities.   Prior to Marriott, Liang worked for Merkle where she was an analytics project lead for Finance and Wealth Management verticals.  Liang holds master and Ph.D. degrees in Engineering from University of Illinois. "
      },
    {
      name: "Sharron Tang",
      image: require("./assets/headshots/sharron_tang.png"),
      title: "Senior AI/ML architect/Machine Learning Specialist, Vanguard",
      bio:
        "With 25 years of dedicated service at The Vanguard Group, Sharron is a Full Stack AI/ML specialist with extensive expertise in Generative AI, machine learning, data engineering, data analytics, data architecture, and AWS solutions. She holds a master’s degree in computer science from the University of North Carolina at Charlotte and a bachelor’s degree in computer and precision engineering obtained overseas. Her certifications include AWS Solution Architect, Generative AI, Machine Learning, and Data Analytics. Throughout her tenure at Vanguard, Sharron has been acknowledged as an innovator and technologist.  She has spearheaded the development of groundbreaking Generative AI products from the ground up, such as Code Modernizer and LineageGPT. These tools have significantly transformed the data management and software development processes, as well as the technological landscape, enhancing overall efficiency and productivity. Sharron is passionate about the ability of AI to revolutionize and optimize the software development process, potentially reducing most manual effort and instigating a substantial change in industry practices."
      },
    {
      name: "Lorraine Knerr",
      image: require("./assets/headshots/lorraine_kner.png"),
      title:
        "AWS Strategic Accounts Head of AI/ML and Data Architecture",
      bio:
        "Lorraine has an extensive background in data architecture, data product design, enterprise data management and leading worldwide data transformation programs. Lorraine has led the AWS Strategic Accounts AI/ML and Data Architecture team since December 2020 where she has leveraged her experience to design and implement artificial intelligence, enterprise data and generative artificial intelligence platforms to deliver differentiating business value to AWS’s top 25 Strategic Accounts, including Adobe, Intuit and Netflix. Recently Lorraine was selected to lead the AWS Generative AI Acceleration Team helping AWS customers define and implement modern data architectures to accelerate customer development of generative AI applications driving increased workforce productivity and customer product development. In her 3+ years at AWS, Lorraine has spoken at re:Invent on the importance in ID&E in AI and founded the AWS Strategic Accounts Women in Solutions (WISe) affinity group. Lorraine is an AWS thoughtleader on data architecture, data engineering and machine learning advising customers on strategic data initiatives. Aa an AI and data leader, Lorraine has influenced the development of AWS analytics and generative AI services such as DataZone, Glue, Redshift, and Bedrock. Prior to joining AWS, Lorraine was the Chief Data Architect for British Petroleum (BP) and led the design and development of BP’s multi-cloud data fabric architecture on AWS and Azure while leading a global data product team of 150 data professionals comprised of data product owners, data architects, data engineers, and machine learning engineers. Prior to joining BP, Lorraine held various data leadership roles at Capital One, Accolade Inc., ING Direct and Bank of America. Lorraine graduated from Villanova University with a degree in Business Communications and Administration and received a Women’s Executive Leadership certification from The Wharton School of the University of Pennsylvania."
      },
    {
      name: "Shana Sood",
      image: require("./assets/headshots/shana_sood.png"),
      title: "VP, Head of Core Performance Marketing Channels and Advanced Analytics, Capital One",
      bio:
        "Shana Sood is a results-oriented executive with expertise in B2C marketing and tech-enabled personalization for direct-to-consumer businesses. She specializes in building and leading highly motivated teams by blending empathetic leadership with a high standard for performance. Shana has helped organizations unlock incremental value by leveraging relevant talent, customer-centric data, MarTech, and AdTech. She is experienced in driving lasting change within highly matrixed organizations across various industries, including financial services, banking, healthcare, and e-commerce. Currently she leads core Performance Marketing channels at Capital One. Shana has previously worked at Fidelity Investments and McKinsey."
    },
      {
        name: "Giuliana Quiroz",
        image: require("./assets/headshots/guiliana_quiroz.png"),
        title: "Head of Marketing Science, Vanguard",
        bio:
          "Giuliana is seasoned analytics professional with 25 years of experience in media, direct marketing, measurement, database marketing, personalization, digital analytics, and customer insights with a specific focus in financial services.  She joined Vanguard more than 3 years ago to lead the measurement strategy for Integrated Marketing by AI/ML methodologies to tackle sales attribution.  Prior to Vanguard, she led various analytics organizations at TIAA, TD Ameritrade, and JP Morgan Chase. Her past roles focused on driving new digital and analytical capabilities to improve acquisition, retention, and cross-sell/upsell efforts and optimize marketing return on investments. Giuliana earned a BA/BS in Psychology and Mathematics from Kean University and a Master of Educational Psychology in Statistics, Measurement, and Evaluation from Rutgers University.  "
        },
        {
          name: "Caroline Harrison",
          image: require("./assets/headshots/caroliner_harrison.png"),
          title: "Data Scientist – Specialist",
          bio:
            "Caroline Harrison, Data Scientist - Specialist, Center for Analytics and Insights at Vanguard.  Caroline began working at Vanguard two years ago after completing her MSE in Data Science at the University of Pennsylvania.  During this time, she has worked as both a data analyst and data scientist for the Client Experience and Digital Group, and has demonstrated her expertise in NLP and Deep Learning. Outside of work, Caroline enjoys running and playing squash, reading, and cooking.  "
          },
          {
            name: "Hannah Horng",
            image: require("./assets/headshots/hannah_horng.png"),
            title: "Senior Scientist in Biostatistics, Merck",
            bio:
              `Hannah Horng is a Senior Scientist, Biostatistics in Biometrics Research at Merck. She obtained her PhD in Bioengineering at the University of Pennsylvania in 2023, where her dissertation specialized in correction and detection of batch effects in medical imaging data to improve the robustness of models to image acquisition parameters. She was also an HHMI-NIBIB Interfaces Fellow in Medical Imaging, where she completed the pre-clinical curriculum at the Perelman School of Medicine to better understand clinical needs in translational research. In her current role at Merck, she provides statistical support for clinical assays and develops computer vision approaches for scientific imaging generated as part of the drug development pipeline.<br/><br/>Hannah obtained her undergraduate degrees in Bioengineering and Mathematics from the University of Maryland, College Park in 2019. Her research was focused on biophotonics and medical applications for optical imaging. She started her PhD in Bioengineering at the University of Pennsylvania in 2019. Her dissertation research derived new statistical methods for detecting and correcting batch (or nuisance) effects in medical imaging data. After completing her dissertation in 2023, she joined Merck as a Senior Scientist, Biostatistics in Biometrics Research. Her current responsibilities include statistical support for clinical assays and developing computer vision approaches for scientific imaging generated as part of the R&D pipeline.` },
        {
          name: "Susan B. Davidson",
          image: require("./assets/headshots/susan_davidson.png"),
          title: "Weiss Professor of Computer and Information Science, Co-Director, Data Science Program, University of Pennsylvania",
          bio:
            `Susan B. Davidson received the B.A. degree in Mathematics from Cornell University, Ithaca, NY, in 1978, and the M.A. and Ph.D. degrees in Electrical Engineering and Computer Science from Princeton University, Princeton NJ, in 1980 and 1982. Dr. Davidson is the Weiss Professor of Computer and Information Science (CIS) at the University of Pennsylvania, where she has been since 1982. She was the founding co-director of the Penn Center for Bioinformatics from 1997-2003,  the founding co-director of the Greater Philadelphia Bioinformatics Alliance, and served as Deputy Dean of the School of Engineering and Applied Science from 2005-2007 and Chair of CIS from 2008-2013.  Her research interests include data management for data science, database and web-based systems, provenance, crowdsourcing, and data citation.<br/><br/> Dr. Davidson is a Fellow of the AAAS, ACM Fellow, Corresponding Fellowship of the Royal Society of Edinburgh, received the Lenore Rowe Williams Award, and was a Fulbright Scholar and recipient of a Hitachi Chair.  She received the IEEE Technical Committee of Data Engineering Impact Award, the Lindback Distinguished Teaching Award, the Ruth and Joel Spira Award for Excellence in Teaching, the Trustees Council of Penn Women/Provost Award for her work on advancing women in engineering and served as Chair of the board of the Computing Research Association.`
          },
        {
          name: "Kimberly Ryan",
          image: require("./assets/headshots/kim_ryan.png"),
          title:
            "Senior Director, Head of Data Defense Office, Vanguard",
          bio:
            `Kim has been with Vanguard for twenty-years and is currently a Senior Director leading the Data Defense Office in the Chief Data Analytics Office. In this role, Kim is responsible for data identification, data de-identification, data deletion, supplier defense, data and AI governance.<br/><br/> Prior to leading the Data Defense team, Kim led the Service Management team within Global Technology Operations for ten years. She was responsible for strategic planning, infrastructure architecture and financial management. In addition, she led the ServiceNow development team, asset management, configuration management, data center services, and managed client communications. In the recent past, Kim has taken on several other responsibilities and successfully led the Return to Office program for IT following the Covid pandemic, enhanced the client experience by improving the meeting room experience and developed the mainframe modernization strategy for Vanguard. <br/><br/>Prior to joining IT, Kim spent eight years in the Institutional Business in various roles, including leading the Business Management Office for Operations. She spent her first two years at Vanguard in Corporate Financial Services as a Financial Analyst.<br/><br/> Prior to joining Vanguard, Kim spent several years in the forensic accounting and business valuation industries, supporting multiple large scale court cases on the East Coast. Kim has a bachelor’s degree in business administration from Drexel University and holds several certifications including Professional Agile Leadership.`
           },
        {
          name: "Seung-Youn Oh",
          image: require("./assets/headshots/seong_youn.png"),
          title: "Associate professor in the Department of Political Science at Bryn Mawr College",
          bio:
            `Seung-Youn Oh is an associate professor in the Department of Political Science at Bryn Mawr College, specializing in international relations and comparative politics in East Asia. She also serves as faculty director of the China and Korea sections of the Global Immersion Program  [gip.wharton.upenn.edu]at Wharton Business School, and a visiting professor at INSEAD Business School. <br/><br/> Her broader academic interests include China’s economic statecraft, the nexus between economy and security, trade disputes, industrial policy, global supply chains of emerging technology, and US-China strategic competition/cooperation. In the past, she  was a POSCO Fellow at the East-West Center  [eastwestcenter.org]in Honolulu (2016-2017),  a research fellow at the East Asia Institute [eai.or.kr] in Seoul, Korea (2014-2015), a post-doctoral fellow at the Center for the Study of Contemporary China at the University of Pennsylvania [cscc.sas.upenn.edu] (2012-2013),  and a visiting scholar at the Institute of World Economics and Politics of the Chinese Academy of Social Science  [en.iwep.org.cn]in Beijing, China (2009-2010). She holds an M.A. and Ph.D. in Political Science from the University of California at Berkeley and a B.A. in Political Science from Yonsei University in South Korea.`
          },
          {
            name: "Tingni Sun",
            image: require("./assets/headshots/tinguini_sun.png"),
            title:
              "Senior Manager, Data Science, Vanguard",
            bio:
              "Tingni Sun is a seasoned Data Science leader with over 10 years of experience in data science, machine learning and statistical modeling. She leads a team of data scientists at Vanguard, driving AI/ML and analytical initiatives to enhance the experience for Vanguard's institutional and intermediary clients. She is experienced in developing business-focused solutions utilizing recommendation systems, deep learning, causal analysis, natural language processing, among other techniques. Before joining Vanguard, Tingni was an Assistant Professor of Statistics at the University of Maryland, College Park, where she conducted research in high-dimensional statistical inference, nonparametric statistics, and their applications. She holds a Ph.D. in Statistics from Rutgers University and a B.S. in Mathematics and Applied Mathematics from Peking University."
            },
          {
            name: "Kennedy Ellison",
            image: require("./assets/headshots/kennedy_ellsion.png"),
            title: "Data Scientist – Specialist",
            bio:
              `Kennedy is a data scientist within Vanguard's Center for Analytics and Insights. She began working at Vanguard five years ago, initially as a software developer before transitioning into data science a year later. Her data science work has focused on bringing real-time insights and personalization to Vanguard Personal Investor clients. Kennedy earned a B.A in Computer Science and Linguistics from Bryn Mawr College and an M.S.E in Data Science from the University of Pennsylvania.`
            },
            {
              name: "Roya Kandalan",
              image: require("./assets/headshots/Roya_kandalan.png"),
              title: "Research scientist working on advancing artificial intelligence technologies.",
              bio:
                `Roya has a PhD in Electrical Engineering, is a US patent holder, and has multiple publications in primary journals on electrical engineering, mathematics, and artificial intelligence. Her primary research interests are generative AI, computer vision, and pattern recognition. Roya has developed machine learning solutions for various applications, including healthcare, assistive technologies, and security.  She is enthusiastic about advancing science and passionate about women’s rights. She is a Google Developer Expert and Cloud Champion in AI/ML, a Google Woman’s Techmaker Ambassador, and a Google Developer Group co-organizer (Cloud Boston). She uses these platforms to enhance women’s confidence in the workplace and advocate for women's and non-binary individuals' rights.<br/><br/><h4>Webinar title and description: </h4> <br/>Fine Tuning Gemini in AI Studio and Vertex AI Fine-tuning allows a model to excel in a specific domain by customizing its responses to meet structural requirements. This technique is especially valuable when precise output formats are needed, significantly enhancing accuracy for targeted tasks.<br/>
                  In this session, we’ll dive deeper into Gemini, moving beyond basic prompting to focus on optimizing the platform for specialized, tailored applications. By exploring its intricacies, we aim to unlock Gemini's full potential and achieve better outcomes.<br/>
                  To achieve this goal, we leverage Google AI Studio, a sandbox environment for rapid prototyping, and Google Vertex AI, an enterprise-grade solution for fine-tuning, to bring our ideas to life.
                `
              }
];
